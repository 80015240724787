.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
}

.signup-page .logo {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

p,
span {
  font: var(--bould-light-caterpillar);
}

.signup-form-container {
  max-width: 70%;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  margin-bottom: 2.5rem;
}

input,
select {
  margin-bottom: 1rem;
  margin-right: 1rem;
  border: 2px solid #d1d1d1;
  border-radius: 0.5rem;
  font: var(--bould-light-caterpillar);
  color: #2a2f3a;
  padding: 0.75rem;
}

input[type='submit'] {
  margin-top: 1rem;
  color: #7209b7;
  background: none;
  border: 2px solid #7209b7;
  border-radius: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
}

input[type='checkbox'] {
  width: 1rem;
  height: 1rem;
  margin-top: 1rem;
}

input[type='radio'] {
  width: 2rem;
  height: 1rem;
}

.half-width {
  width: 46%;
}

.full-width {
  width: 94%;
}

.signup-page .signup-form-container form input:focus {
  outline: none;
  border: 2px solid #2a2f3a;
}

.signup-page .signup-form-container form form-input::placeholder {
  color: #d1d1d1;
}

.form-check-label {
  color: #2a2f3a;
  display: inline-flex;
}

.success-message {
  width: 300px;
  margin-bottom: 5rem;
  text-align: center;
  color: #7209b7;
  font: var(--bould-regular-caterpillar);
  line-height: 2rem;
}
